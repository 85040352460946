import React, { useState, useEffect } from 'react';
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import ResourceBlock from '../components/resource-post-block'
import '../styles/resources-page.css'
import ogMetaImage from '../../static/entratalogo_1200x630.png'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { checkSvg } from '../helper-functions'

export default function ResourcesPage({ data, pageContext }) {
  let webinarsTab = true
  let guidesTab = true
  let reportsTab = true
  let caseStudiesTab = true
  let coursesTab = true
  const ResourcePosts = data.contentfulResourcesPosts.resourcesPosts
  let allCount = 0
  let webinarCount = 0
  let guideCount = 0
  let studyCount = 0
  let reportCount = 0
  let coursesCount = 0
  const [allSelected, setAllSelected] = useState(true)
  const [webinarSelected, setWebinarSelected] = useState(false)
  const [guideSelected, setGuideSelected] = useState(false)
  const [studySelected, setStudySelected] = useState(false)
  const [reportSelected, setReportSelected] = useState(false)
  const [coursesSelected, setCoursesSelected] = useState(false)
  const allTabSelected = () => {
    setAllSelected(true)
    setWebinarSelected(false)
    setGuideSelected(false)
    setStudySelected(false)
    setReportSelected(false)
    setCoursesSelected(false)
  }
  const webinarTabSelected = () => {
    setAllSelected(false)
    setWebinarSelected(true)
    setGuideSelected(false)
    setStudySelected(false)
    setReportSelected(false)
    setCoursesSelected(false)
  }
  const guideTabSelected = () => {
    setAllSelected(false)
    setWebinarSelected(false)
    setGuideSelected(true)
    setStudySelected(false)
    setReportSelected(false)
    setCoursesSelected(false)
  }
  const studyTabSelected = () => {
    setAllSelected(false)
    setWebinarSelected(false)
    setGuideSelected(false)
    setStudySelected(true)
    setReportSelected(false)
    setCoursesSelected(false)
  }
  const reportTabSelected = () => {
    setAllSelected(false)
    setWebinarSelected(false)
    setGuideSelected(false)
    setStudySelected(false)
    setReportSelected(true)
    setCoursesSelected(false)
  }
  const coursesTabSelected = () => {
    setAllSelected(false)
    setWebinarSelected(false)
    setGuideSelected(false)
    setStudySelected(false)
    setReportSelected(false)
    setCoursesSelected(true)
  }
  useEffect(() => {
    const url = document.location.href;
    if (url.includes('webinars')) {
      setAllSelected(false)
      setGuideSelected(false)
      setWebinarSelected(true)
    } else if (url.includes('guides')) {
      setAllSelected(false)
      setWebinarSelected(false)
      setGuideSelected(true)
    } else {

    }
  }, []);
  return (
    <Layout>
      <div className="resources-page">
        <div className="resources-intro"><h2>Resource Center</h2></div>
        <div className="resources-tabs">
          <div className="tabs-cap">
            <div className={allSelected ? "tab-nav-item all-tab selected-tab" : "tab-nav-item all-tab"} onClick={(allTabSelected)}>All</div>
            {ResourcePosts?.map((resourcePost, index) => {
              const CheckCategory = resourcePost.contentCategory
              function webinarTabIncluded() {
                webinarsTab = false
              }
              function guideTabIncluded() {
                guidesTab = false
              }
              function caseStudyTabIncluded() {
                caseStudiesTab = false
              }
              function reportTabIncluded() {
                reportsTab = false
              }
              function coursesTabIncluded() {
                coursesTab = false
              }
              return (
                <React.Fragment key={resourcePost?.id}>
                {
                  CheckCategory == 'Webinar' && webinarsTab ?
                    <div className={webinarSelected ? "tab-nav-item webinars-tab selected-tab" : "tab-nav-item webinars-tab"} onClick={(webinarTabSelected)} key={index}>Webinars{webinarTabIncluded()}</div>
                  :
                  CheckCategory == 'Guide' && guidesTab ?
                    <div className={guideSelected ? "tab-nav-item guides-tab selected-tab" : "tab-nav-item guides-tab"} onClick={(guideTabSelected)} key={index}>Guides{guideTabIncluded()}</div>
                  :
                  CheckCategory == 'Case Study' && caseStudiesTab ?
                    <div className={studySelected ? "tab-nav-item casestudy-tab selected-tab" : "tab-nav-item casestudy-tab"} onClick={(studyTabSelected)} key={index}>Case Studies{caseStudyTabIncluded()}</div>
                  :
                  CheckCategory == 'Report' && reportsTab ?
                    <div className={reportSelected ? "tab-nav-item reports-tab selected-tab" : "tab-nav-item reports-tab"} onClick={(reportTabSelected)} key={index}>Reports{reportTabIncluded()}</div>
                  :
                  CheckCategory == 'Courses' && coursesTab ?
                    <div className={coursesSelected ? "tab-nav-item courses-tab selected-tab" : "tab-nav-item courses-tab"} onClick={(coursesTabSelected)} key={index}>Courses{coursesTabIncluded()}</div>
                  : ""
                }
                </React.Fragment>
              )
            })}
          </div>
        </div>
        <div className="resources-tabs-content">
          <div className="content-cap">
          <div className="featured-title">Featured</div>
            <div id="all-content" className={allSelected ? "tab-content all-content selected-tab-content" : "tab-content webinars-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  function updateAll() {
                    allCount = 1
                  }
                  return (
                    <div className={allCount === 0 ? 'grid-item first-grid-item' : 'grid-item'} key={index}>
                      <ResourceBlock resourcePost={resourcePost}/>
                      {updateAll()}
                    </div>
                  )
                })}
              </div>
            </div>
            <div id="webinars-content" className={webinarSelected ? "tab-content webinars-content selected-tab-content" : "tab-content webinars-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  const ResourceCategory = resourcePost.contentCategory
                  function updateWebinar() {
                    webinarCount = 1
                  }
                  return (
                    <React.Fragment key={resourcePost?.id}>
                    {
                      ResourceCategory == 'Webinar' ?
                        <div className={webinarCount === 0 ? 'grid-item first-grid-item' : 'grid-item'}>
                          <ResourceBlock resourcePost={resourcePost}/>
                          {updateWebinar()}
                        </div>
                      :""
                    }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            <div id="guides-content" className={guideSelected ? "tab-content guides-content selected-tab-content" : "tab-content guides-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  const ResourceCategory = resourcePost.contentCategory
                  function updateGuide() {
                    guideCount = 1
                  }
                  return (
                    <React.Fragment key={resourcePost?.id}>
                    {
                      ResourceCategory == 'Guide' ?
                        <div className={guideCount === 0 ? 'grid-item first-grid-item' : 'grid-item'} key={resourcePost?.id}>
                          <ResourceBlock resourcePost={resourcePost}/>
                          {updateGuide()}
                        </div>
                      :""
                    }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            <div id="casestudies-content" className={studySelected ? "tab-content casestudies-content selected-tab-content" : "tab-content casestudies-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  const ResourceCategory = resourcePost.contentCategory
                  function updateStudy() {
                    studyCount = 1
                  }
                  return (
                    <React.Fragment key={resourcePost?.id}>
                    {
                      ResourceCategory == 'Case Study' ?
                        <div className={studyCount === 0 ? 'grid-item first-grid-item' : 'grid-item'} key={resourcePost?.id}>
                          <ResourceBlock resourcePost={resourcePost}/>
                          {updateStudy()}
                        </div>
                      :""
                    }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            <div id="reports-content" className={reportSelected ? "tab-content reports-content selected-tab-content" : "tab-content reports-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  const ResourceCategory = resourcePost.contentCategory
                  function updateReport() {
                    reportCount = 1
                  }
                  return (
                    <React.Fragment key={resourcePost?.id}>
                    {
                      ResourceCategory == 'Report' ?
                        <div className={reportCount === 0 ? 'grid-item first-grid-item' : 'grid-item'} key={resourcePost?.id}>
                          <ResourceBlock resourcePost={resourcePost}/>
                          {updateReport()}
                        </div>
                      :""
                    }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
            <div id="courses-content" className={coursesSelected ? "tab-content courses-content selected-tab-content" : "tab-content courses-content"}>
              <div className="tab-content-grid">
                {ResourcePosts?.slice(0).reverse().map((resourcePost, index) => {
                  const ResourceCategory = resourcePost.contentCategory
                  function updateCourse() {
                    coursesCount = 1
                  }
                  return (
                    <React.Fragment key={resourcePost?.id}>
                    {
                      ResourceCategory == 'Courses' ?
                        <div className={coursesCount === 0 ? 'grid-item first-grid-item' : 'grid-item'} key={resourcePost?.id}>
                          <ResourceBlock resourcePost={resourcePost}/>
                          {updateCourse()}
                        </div>
                      :""
                    }
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = ({data}) => (
  <>
    <meta property="og:title" content={data?.contentfulResourcesPosts?.metadata?.ogMetaTitle ? data?.contentfulResourcesPosts?.metadata?.ogMetaTitle : "Entrata | Resources"}/>
    <meta property="og:description" content={data?.contentfulResourcesPosts?.metadata?.ogMetaDescription ? data?.contentfulResourcesPosts?.metadata?.ogMetaDescription : "Official insights and announcements from the people at Entrata"}/>
    <meta property="og:url" content="https://www.entrata.com/resources" />
    <meta name="description" content={data?.contentfulResourcesPosts?.metadata?.metaDescription ? data?.contentfulResourcesPosts?.metadata?.metaDescription : "Official insights and announcements from the people at Entrata"} />
    <title>{data?.contentfulResourcesPosts?.metadata?.metaTitle ? data?.contentfulResourcesPosts?.metadata?.metaTitle : "Entrata | Resources"}</title>
    <meta property="og:type" content={data?.contentfulResourcesPosts?.metadata?.metaType ? data?.contentfulResourcesPosts?.metadata?.metaType : "image"} />
    <meta property="og:image" content={data?.contentfulResourcesPosts?.metadata?.ogMetaImage?.url ? data?.contentfulResourcesPosts?.metadata?.ogMetaImage?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query ResourcesPage {
    contentfulResourcesPosts {
      metadata {
        metaDescription
        metaTitle
        ogMetaDescription
        ogMetaTitle
        ogMetaType
        ogMetaImage {
          url
        }
      }
      resourcesPosts {
        id
        headline
        contentCategory
        date
        slug
        bodyText {
          bodyText
        }
        image {
          image {
            gatsbyImageData(width: 800, placeholder: BLURRED)
            description
            url
          }
          description
          fitToFrame
        }
        button {
          fullUrl
          slug
          buttonText
        }
      }
    }
  }
`
