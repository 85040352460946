import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LinkArrow from '../assets/link-arrow.svg'
import ResourcesCasestudyIcon from '../assets/resources-casestudy-icon.svg'
import ResourcesGuidesIcon from '../assets/resources-guide-icon.svg'
import ResourcesReportsIcon from '../assets/resources-report-icon.svg'
import ResourcesWebinarsIcon from '../assets/resources-webinar-icon.svg'
import ResourcesCoursesIcon from '../assets/resources-courses-icon.svg'

export default function ResourcesPage({ resourcePost }) {
  const ResourceCategory = resourcePost.contentCategory
  const resourceImage = getImage(resourcePost?.image?.image)
  const button = resourcePost?.button

  return (
    <>
      {
        resourceImage ?
          <div className="resource-image-holder">
            <GatsbyImage image={resourceImage} alt={resourcePost?.image?.description} className="resource-image" />
          </div>
        : ""
      }
      <div className={resourceImage ? "resource-text-content" : "resource-text-content resource-text-full" }>
        <div className={resourceImage ? "resource-text-align" : "resource-text-align resource-text-relative"}>
          {
            ResourceCategory == 'Webinar' ?
              <><ResourcesWebinarsIcon className="resources-icon"/><div className="resources-category-text">Webinars</div></>
            :
            ResourceCategory == 'Guide' ?
              <><ResourcesGuidesIcon className="resources-icon"/><div className="resources-category-text">Guides</div></>
            :
            ResourceCategory == 'Case Study' ?
              <><ResourcesCasestudyIcon className="resources-icon"/><div className="resources-category-text">Case Studies</div></>
            :
            ResourceCategory == 'Report' ?
              <><ResourcesReportsIcon className="resources-icon"/><div className="resources-category-text">Reports</div></>
            :
            ResourceCategory == 'Courses' ?
              <><ResourcesCoursesIcon className="resources-icon"/><div className="resources-category-text">Courses</div></>
            : ""
          }

          <div className="resources-grid-headline">{resourcePost?.headline}</div>
          <div className="resources-grid-text">
            {resourcePost?.bodyText?.bodyText.split(" ").splice(0,30).join(" ") + "..."}
          </div>
          <div className="resource-links">
            {
              button && resourcePost.button?.fullUrl ?
                <a className="button-default solid-dark-button" href={button?.fullUrl}>{button?.buttonText}</a>
              : ""
            }
            {
              button && resourcePost.button?.slug ?
                <Link className="button-default solid-dark-button" to={button?.slug}>{button?.buttonText}</Link>
              : ""
            }
            <Link className="cta-link-default dark-cta-link" to={resourcePost?.slug}>Read More<span className="link-arrow-icon"><LinkArrow /></span></Link>
          </div>
        </div>
      </div>
    </>
  )
}
